<template>
  <div id="app"
    class="app"
    :class="{ 'hide-cursor': isTerminal && !isTerminalMouseCursorEnabled }"
    :style="getBgImage"
    >
    <TicketHistory v-if="!isTerminal"/>
    <Modal v-if="ticketCanceled"
             showFooter
             class="cancel-ticket-confirm-modal"
             @closeModal="closeCancelTicketModal"
             @closeModalOutside="closeCancelTicketModal('outside')"
             ref="modal">
        <h3 slot="header">
          {{ translations.general_info }}
        </h3>
        <div slot="body">
          {{ ticketCanceledMessage }}
          <div class="modal-actions">
            <button class="button"
                    @click="closeCancelTicketModal">
              {{ translations.general_ok }}
            </button>
          </div>
        </div>
        <div slot="footer">
          <button class="button"
                  @click="closeCancelTicketModal">
            {{ translations.general_ok }}
          </button>
        </div>
      </Modal>
    <router-view></router-view>
  </div>
</template>

<script>
import {
  applyPalette,
  Modal,
  gtag,
  eventBus,
} from '@nsftx/games-sdk-js';
import { mapGetters, mapActions } from 'vuex';
import { BusService } from '@nsftx/games-bus';
import {
  has,
  merge,
  isArray,
  startCase,
  assign,
} from 'lodash';
import isMobile from 'ismobilejs';
import store from '@/store';
import TicketHistory from '@/components/TicketHistory';
import webViewIntegration from '@/utility/webViewIntegration';

export default {
  name: 'App',
  data() {
    return {
      busService: null,
      isLoggedIn: false,
      check: null,
      checkTimeout: 10000,
      ticketCanceled: false,
      canceledTicket: {},
      lastTicketsCalled: false,
    };
  },
  components: {
    TicketHistory,
    Modal,
  },
  computed: {
    ...mapGetters([
      'gamesBetslip/ticket',
      'gamesBetslip/totalPayment',
      'betslip/future',
      'betslip/payment',
      'config',
      'user/user',
      'translations',
      'currentRound',
      'disabledRound',
      'expiredBets',
      'isIsonis',
      'offer',
      'disabledRoundBySocket',
      'userUuid',
      'jackpotState',
      'isSetStateTransfered',
      'terminalUserData',
      'isTerminal',
      'ticketDetailsData',
      'activeTerminalLayout',
      'isTerminalMouseCursorEnabled',
      'userIsOperator',
      'ajsDisabled',
    ]),
    ...mapGetters('notifications', ['notificationTypes']),
    ...mapGetters({
      isUserLoggedIn: 'user/isLoggedIn',
    }),
    tickets() {
      return this['gamesBetslip/ticket'];
    },
    isMob() {
      return isMobile().any || window.innerWidth <= 660;
    },
    userProfile() {
      return this['user/user'];
    },
    ...mapGetters({
      isBetslipOpened: 'betslip/isBetslipOpened',
      betslipComponents: 'betslip/betslipComponents',
    }),
    getBgImage() {
      if (this.activeTerminalLayout === 'RacersTicketDetails' || this.activeTerminalLayout === 'RacersHistory') {
        return {
          backgroundColor: 'var(--card)',
          backgroundImage: 'none',
        };
      }
      return this.config.ui.dark ? this.bgImage : this.bgImageLight;
    },
    bgImage() {
      return {
        // eslint-disable-next-line
        backgroundImage: `linear-gradient(to bottom, rgba(26, 28, 29, 0), #1A1C1D 88%), url(${require('@/assets/' + this.config.productName + '_dark_bg.jpg')})`,
      };
    },
    bgImageLight() {
      return {
        // eslint-disable-next-line
        backgroundImage: `linear-gradient(to bottom, rgba(26, 28, 29, 0), #FFFFFF 88%), url(${require('@/assets/' + this.config.productName + '_light_bg.jpg')})`,
      };
    },
    isAndroidPlatform() {
      return webViewIntegration.isActive() && has(window, 'WebAppListener');
    },
    getProductName() {
      return this.config.productName.split(/(?=[A-Z])/).join(' ');
    },
    ticketCanceledMessage() {
      return this.translations.general_ticket + ' ' + this.canceledTicket.id + ' '
        + this.translations.general_successfully_cancelled + '!';
    },
    getTicketDetailsAuth() {
      return btoa(`${this.config.user.id}:${this.terminalUserData.auth.password}`);
    },
    getTicketHistoryAuthHeaders() {
      const headers = {
        Authorization: `Bearer ${this.terminalUserData.auth.token}`,
        'HTTP-X-NAB-BETSHOP-ID': this.config.betshop.id,
        'HTTP-X-NAB-COMPANY-ID': this.config.companyData.id,
        'HTTP-X-NAB-DEVICE-UUID': this.config.device.deviceUuid,
        'HTTP-X-NAB-DP': 'AdminTerminal',
        'SEVEN-LOCALE': this.config.locale,
      };
      return headers;
    },
    productNameLabel() {
      return this.translations[this.config.productNameLabel];
    },
    isStandAlone() {
      return this.config.ui.config.mode === 'standalone';
    },
    isGravityGateway() {
      return this.config.messageProtocol.name === 'gravityGateway';
    },
  },
  methods: {
    ...mapActions([
      'setState',
      'stopBetting',
      'setOffer',
      'parseOdds',
      'payTheTicket',
      'setUserData',
      'setUserBalance',
      'updateTicketValues',
      'notifications/clearNotification',
      'setExpiredBets',
      'setDisabledRoundBySocket',
      'setUserUuid',
      'setIsMobile',
      'setJackpotState',
      'setStateMessage',
      'transferSetStateMessage',
      'setNextRoundTime',
      'setInProgressRound',
      'setCurrentScheduleRound',
      'setTicketHistoryTerminal',
      'setTicketDetailsData',
      'setLoaded',
    ]),
    ...mapActions({
      clearBetslip: 'gamesBetslip/clearBetslip',
      setNotification: 'notifications/setNotification',
      getLastTickets: 'gamesBetslip/getLastTickets',
      setScheduleDisabled: 'betslip/setScheduleDisabled',
      setIsPayinButtonDisabled: 'gamesBetslip/setIsPayinButtonDisabled',
      getUserBalance: 'user/getUserBalance',
    }),
    ...mapActions('gamesBetslip', [
      'updateTicket',
      'setIsPayinButtonDisabled',
      'setIsPayinInProgress',
      'removeBets',
      'updateSelectedBet',
      'updateBet',
      'addPreBet',
    ]),
    getMarketLabel(type) {
      return `${type}MARKET TEST`;
    },
    setGtag(device) {
      if (this.config.ui.config.gtm) {
        const { code, auth, preview } = this.config.ui.config.gtm[device];
        gtag.setup(code, auth, preview);
        return;
      }
      gtag.setup();
    },
    closeCancelTicketModal() {
      this.ticketCanceled = false;
    },
    formatTicketBets() {
      if (this.isTerminal) {
        return this.tickets.map((n) => ({
          payin: Number(n.stake) || Number(this.totalPayment),
          stake: n.stake,
          type: n.betTypeId,
          value: n.racers,
          displayValue: n.displayValue,
          event: n.event,
          numOfComb: n.numOfComb,
          id: n.betTypeId,
          input: n.input,
          displayName: n.displayName,
          numEvents: 1,
          eventId: n.eventId,
        }));
      }
      return this.tickets.map((n) => ({
        payin: Number(n.stake) || Number(this.totalPayment),
        stake: n.stake,
        type: n.betTypeId,
        value: n.racers,
        numEvents: 1,
        eventId: n.eventId,
      }));
    },
    setTheme(config) {
      const rootElement = document.documentElement;
      const bodyElement = document.body;
      const baseColor = config.ui[config.ui.config.baseColor];
      const theme = config.ui[config.ui.config.theme];
      applyPalette.set(baseColor, rootElement);
      applyPalette.set(theme, bodyElement);
    },
    setUser(user) {
      if (user.user) {
        this.isLoggedIn = !!user.token;
        store.dispatch('setUserData', user.user);
        if (this.isLoggedIn) {
          this.setUserUuid(user.user.id);
          this.togglePlayerChannel(this.userProfile.profile.id);
          store.dispatch('user/updateUser', {
            auth: {
              token: user.token,
              tpToken: user.tpToken,
            },
            profile: user.user,
          });
          if (!this.lastTicketsCalled) {
            this.getLastTickets();
          }
          this.lastTicketsCalled = !!user.token;
        }
      }
    },
    clearCheckTimeout() {
      clearTimeout(this.check);
    },
    checkTicket(requestUuid) {
      this.check = setTimeout(() => {
        store.dispatch('gamesBetslip/ticketCheck', requestUuid).then((response) => {
          if (response.data.status.value.toUpperCase() !== 'PENDING') {
            const message = {
              message: this.translations.general_ticket_confirmed,
              status: 'positive',
              notificationTimeout: 1,
            };
            if (!this.isTerminal) {
              this.getLastTickets();
            }
            store.dispatch('notifications/clearNotification').then(() => {
              store.dispatch('notifications/setNotification', message);
            });
            this.setIsPayinButtonDisabled(false);
            this.setIsPayinInProgress(false);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            (async () => {
              await this.clearBetslip();
            })();
            store.dispatch('bettingDisable', false);
          }
          if (this.disabledRoundBySocket) {
            this.stopBetting(this.disabledRoundBySocket);
          } else {
            this.stopBetting(0);
          }
          this.setScheduleDisabled(false);
          this.stopBetting(0);
          this.clearCheckTimeout();
          this.setIsPayinButtonDisabled(false);
          store.dispatch('gamesBetslip/setIsPayinInProgress', false);
        });
        this.setIsPayinButtonDisabled(false);
        store.dispatch('gamesBetslip/setIsPayinInProgress', false);
        store.dispatch('notifications/clearNotification');
      }, this.checkTimeout);
    },
    getPrintTemplate() {
      let template = '';
      const messages = this.translations;
      const { currency } = this.config;
      const tempTickets = JSON.parse(JSON.stringify(this.tickets));
      const printBets = merge(tempTickets, this.formatTicketBets());
      template += `${this.getProductName};`;
      template += "{{ ticketDateTimeUTC | date('d.m.Y H:i:s', false) }};";
      template += `${messages.general_pay_in} {{ payin | number_format(2, '.', ',') }}${currency} `;
      template += `${messages.general_payout} {{ payout | number_format(2, '.', ',') }}${currency} `;

      return (
        template + printBets.map((bet) => {
          const betOutcome = isArray(bet.racers) ? bet.racers.join(',') : bet.racers;
          let body = '';

          for (let i = 0; i < bet.numEvents; i += 1) {
            body += `${betOutcome} `;
            body += `${bet.market} `;
            body += `${bet.round} `;
            body += `${bet.odds === null ? null : bet.odds.toFixed(2)} `;
            body += `${bet.payin} `;
          }
          return body;
        }).join('; ') + '{{ id }}'
      );
    },
    setPageTitle() {
      const { productFqn, applicationName } = this.config;
      document.title = `${startCase(productFqn)}: ${applicationName}`;
    },
    togglePlayerChannel(playerId) {
      if (playerId) {
        this.busService.addChannel(`${this.config.productName}.Player`, playerId);
      } else {
        this.busService.removeChannel(`${this.config.productName}.Player`, this.userProfile.profile.id);
      }
    },
  },
  beforeMount() {
    this.busService = new BusService({
      environment: this.config.environment,
      platform: this.config.platform,
      productName: this.config.productName,
      productId: this.config.productId,
      tenantId: this.config.tenantId,
      locale: this.config.locale,
      applicationName: this.config.applicationName,
      subChannels: [],
      useCommonEventName: true,
      adapters: [
        { name: 'GcmAdapter' },
      ],
      clientMessageProtocol: this.config.messageProtocol,
      bounceToFrames: [
        { frameId: 'racersVisualization' },
      ],
    });
    const device = this.isMob ? 'mobile' : 'desktop';
    this.busService.start();
    this.setGtag(device);
    this.setIsMobile(isMobile().any && window.innerWidth <= 640);
    (async () => {
      if (!this.ajsDisabled) {
        await this.setJackpotState();
        if (this.isTerminal) {
          this.busService.addChannel('Device', this.config.device.uuid);
        }
        if (this.jackpotState) {
          this.busService.addChannel('Jackpot', this.jackpotState.jackpotId);
        }
      }
    })();
  },
  mounted() {
    this.setPageTitle();
    this.setTheme(this.config);
    if (this.isAndroidPlatform) {
      window.WebAppListener.sendMessage('Slave.Loaded');
    }
    this.busService.sendMessage({
      action: 'Slave.Loaded',
    }, true);
    window.addEventListener('Bus:Message', (event) => {
      const { eventName } = event.detail;
      switch (eventName) {
        case 'SetState':
          if (event.detail.eventId) {
            this.setCurrentScheduleRound(event.detail.eventId);
          }
          this.setState(event.detail);
          if (event.detail.eventId) {
            this.setInProgressRound(event.detail.eventId);
            this.setNextRoundTime(event.detail.offer[0].startDate.date);
          }
          if (!this.isSetStateTransfered && event.detail.eventId && event.detail.type) {
            this.setStateMessage(event.detail);
            this.transferSetStateMessage(true);
          }
          if (event.detail.type === 'race' && event.detail.eventId === event.detail.offer[0].eventIdToday) {
            this.stopBetting(event.detail.eventId);
            setTimeout(() => {
              eventBus.$emit('switchToNextRound');
            }, 1000);
          }
          if (event.detail.type === 'bettingDisabled') {
            this.stopBetting(event.detail.eventId);
            setTimeout(() => {
              eventBus.$emit('switchToNextRound');
            }, 1000);
          }
          if (this.config?.state?.odds) {
            this.parseOdds(this.config.state.odds);
          }
          if (this.config?.state?.offer) {
            this.setOffer(this.config.state.offer);
          }
          this.setLoaded(true);
          break;
        case 'StartEvent':
          if (this.isTerminal && this.ticketDetailsData) {
            if (this.ticketDetailsData.status.id !== '5') {
              fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${this.ticketDetailsData.id}.json`, {
                headers: {
                  Authorization: `Basic ${this.getTicketDetailsAuth}`,
                },
              })
                .then((response) => response.text())
                .then((text) => this.setTicketDetailsData(JSON.parse(text)));
            }
          }
          this.setCurrentScheduleRound(event.detail.eventId);
          this.setNextRoundTime(event.detail.offer[0].startDate.date);
          store.dispatch('notifications/clearNotification');
          this.setState(event.detail);
          this.stopBetting(0);
          this.setOffer(event.detail.offer);
          this.parseOdds(this.config.state.odds);
          eventBus.$emit('updateSchedule');
          if (this.isLoggedIn) {
            this.getLastTickets();
            if (this.isStandAlone && !this.isGravityGateway) {
              this.getUserBalance();
            }
          }
          break;
        case 'StopBetting':
          this.stopBetting(event.detail.eventId);
          this.setDisabledRoundBySocket(event.detail.eventId);
          this.updateTicketValues();
          if (this.expiredBets) {
            store.dispatch('notifications/clearNotification').then(() => {
              store.dispatch('notifications/setNotification', {
                message: this.translations.general_invalid_bets_accept,
                status: 'neutral',
                type: this.notificationTypes.INVALID_BETS,
              });
            });
          }
          break;
        case 'StartRace':
          this.setInProgressRound(event.detail.eventId);
          this.offer.shift();
          eventBus.$emit('updateSchedule');
          if (this.isLoggedIn) {
            this.getLastTickets();
          }
          break;
        case 'User.AuthorizationChanged':
          if (!this.isTerminal) {
            const authData = event.detail.data.auth;
            if (authData) {
              const playerId = authData?.user?.id || null;
              this.togglePlayerChannel(playerId);
              this.setUser(authData);
            }
            if (this.isLoggedIn) {
              this.setUserBalance(event.detail.data.auth.user.balance);
            } else {
              this.setIsPayinButtonDisabled(false);
              this.busService.removeChannel(`${this.config.productName}.Player`, this.userUuid);
              //  this.setPlayerTickets([]);
              this.lastTicketsCalled = false;
              this.clearBetslip();
              eventBus.$emit('closeTicketHistory');
            }
            if (this.isStandAlone && this.isGravityGateway) {
              this.getUserBalance();
            }
          }
          break;
        case 'User.ProfileChanged':
          this.setUserBalance(event.detail.data.profile.balance);
          break;
        case 'User.BalanceChanged':
          this.setUserBalance(event.detail.data.balance);
          break;
        case 'TicketUpdate': {
          if (this.isUserLoggedIn && !this.isTerminal) {
            const message = {
              message: this.translations.general_ticket_confirmed,
              status: 'positive',
              notificationTimeout: 1,
            };
            if (event.detail.id && event.detail.status.id === '0') {
              this.busService.sendMessage({
                action: 'Tickets.Resolved',
                data: {
                  accepted: true,
                  ticket: event.detail,
                },
              }, true);
              if (this.isAndroidPlatform) {
                window.WebAppListener.sendMessage(JSON.stringify({
                  action: 'Tickets.Resolved',
                  data: {
                    accepted: true,
                    ticket: event.detail,
                  },
                }));
              }
            }
            if (event.detail.id && event.detail.status.id === '1') {
              message.message = this.translations.general_ticket_canceled;
              message.status = 'neutral';
              this.canceledTicket = event.detail;
              this.ticketCanceled = true;
              this.$root.$emit('TicketCancelled');
            }
            if (event.detail.code) {
              message.message = event.detail.message;
              message.status = 'negative';
              eventBus.$emit('SwitchToBetslipTab');
              store.dispatch('notifications/clearNotification').then(() => {
                store.dispatch('notifications/setNotification', message);
              });
            } else {
              (async () => {
                await this.clearBetslip();
                store.dispatch('notifications/clearNotification').then(() => {
                  store.dispatch('notifications/setNotification', message);
                });
              })();
            }
            this.getLastTickets();
            this.setIsPayinButtonDisabled(false);
            store.dispatch('betslip/setTicketPayinLoader', false);
            this.setIsPayinInProgress(false);
            this.clearCheckTimeout();
            if (this.disabledRoundBySocket) {
              this.stopBetting(this.disabledRoundBySocket);
            } else {
              this.stopBetting(0);
            }
            if (this.isBetslipOpened) {
              store.dispatch('betslip/showBetslip');
            }
            this.setScheduleDisabled(false);
            if (this.isStandAlone && !this.isGravityGateway) {
              this.getUserBalance();
            }
          }
          if (this.isTerminal) {
            const ticket = JSON.parse(JSON.stringify(event.detail));
            ticket.ticketPin = event.detail.ticketPin;
            if (event.detail.status.id === '0' && this.activeTerminalLayout === 'Racers') {
              ticket.translation = this.productNameLabel;
              ticket.payinConfirmation = this.translations['print.payin_confirmation'];
              const additionalData = {
                clientPrintJobIdentifier: {
                  id: 'TicketPayIn',
                  uuid: ticket.requestUuid,
                },
              };
              this.busService.sendMessage({
                action: 'Peripherals.Print',
                enforceEvent: true,
                data: {
                  type: 'ticket',
                  productId: 'GreyhoundRaces',
                  data: ticket,
                  additionalData,
                },
              }, true);
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Add',
                  ticketData: ticket,
                  showMessage: false,
                },
              }, true);
              this.busService.sendMessage({
                action: 'Dialog.Show',
                data: {
                  message: this.translations['ticket.add_success'],
                  type: 'success',
                  delay: 3000,
                },
              }, true);
            }
            if (event.detail.status.id === '5' && this.activeTerminalLayout === 'RacersTicketDetails') {
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Payout',
                  ticketData: ticket,
                  showMessage: false,
                },
              }, true);
              if (this.userIsOperator) {
                this.busService.sendMessage({
                  action: 'Peripherals.Print',
                  enforceEvent: true,
                  data: {
                    layoutName: `ticket${this.config.productAlias}Payout`,
                    productId: this.config.productAlias,
                    data: ticket,
                  },
                }, true);
              }
              this.busService.sendMessage({
                action: 'Dialog.Show',
                data: {
                  message: this.translations['ticket.payout_success_to_balance']
                    .replace('{{amount}}', event.detail.payout.toFixed(2)),
                  type: 'success',
                  delay: 3000,
                },
              }, true);
              fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${this.ticketDetailsData.id}.json`, {
                headers: {
                  Authorization: `Basic ${this.getTicketDetailsAuth}`,
                },
              })
                .then((response) => response.text())
                .then((text) => this.setTicketDetailsData(JSON.parse(text)));
            }
            if (event.detail.status.id === '1' && this.activeTerminalLayout === 'RacersTicketDetails') {
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Cancel',
                  ticketData: ticket,
                  showMessage: false,
                },
              }, true);
              this.busService.sendMessage({
                action: 'Dialog.Show',
                data: {
                  message: this.translations['ticket.cancel_success_to_balance']
                    .replace('{{amount}}', event.detail.cancelledAmount.toFixed(2)),
                  type: 'success',
                  delay: 3000,
                },
              }, true);
              this.setTicketDetailsData(ticket);
            }
            if (event.detail.code) {
              this.busService.sendMessage({
                action: 'Dialog.Show',
                data: {
                  message: event.detail.message,
                  type: 'warning',
                  delay: 3000,
                },
              }, true);
              if (event.detail.code === 7000) {
                this.setBetslipLockMessage(event.detail.message);
                //  this.lockBetslip(true);
              }
              this.setIsPayinInProgress(false);
            }
            this.stopBetting(0);
            this.setScheduleDisabled(false);
            this.setIsPayinButtonDisabled(false);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            this.clearCheckTimeout();
            this.clearBetslip();
            this.setIsPayinInProgress(false);
          }
          break;
        }
        case 'Tickets.FetchHistory':
          fetch(`${process.env.VUE_APP_TERMINAL_TICKET_HISTORY_PATH}/${this.config.productId}.json?deviceUuid=${this.config.device.deviceUuid}&timeFrom=${event.detail.data.timeFrom}&timeTo=${event.detail.data.timeTo}&count=${event.detail.data.count}`, {
            headers: this.getTicketHistoryAuthHeaders,
          })
            .then((response) => response.text())
            .then((text) => this.setTicketHistoryTerminal(JSON.parse(text)));
          break;
        case 'Tickets.Checked':
          // eslint-disable-next-line
          let ticketPin = null;
          if (event.detail.data.ticket.ticketPin) {
            ticketPin = event.detail.data.ticket.ticketPin;
          }
          this.setLoaded(false);
          fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${event.detail.data.ticket.id}.json`, {
            headers: {
              Authorization: `Basic ${this.getTicketDetailsAuth}`,
            },
          })
            .then((response) => response.text())
            .then((text) => {
              const formated = JSON.parse(text);
              formated.ticketPin = ticketPin;
              this.setTicketDetailsData(formated);
            });
          break;
        default:
          break;
      }
    });
    eventBus.$on('RemoveInvalidBets', () => {
      this.setExpiredBets(false);
      store.dispatch('notifications/clearNotification');
    });
    eventBus.$on('LoginRequired', () => {
      this.busService.sendMessage({
        action: 'User.LoginRequired',
      }, true);
      if (this.isAndroidPlatform) {
        window.WebAppListener.sendMessage('User.LoginRequired');
      }
    });
    eventBus.$on('LoadLastTickets', () => {
      this.getLastTickets();
    });
    eventBus.$on('TicketPayin', () => {
      if (!this.isTerminal) {
        this.setScheduleDisabled(true);
        this.setIsPayinInProgress(true);
        this.stopBetting(this.currentRound);
        this.setIsPayinButtonDisabled(true);
        store.dispatch('gamesBetslip/payIn', {
          additionalInfo: this.isIsonis ? {
            printTemplate: this.getPrintTemplate(),
            timezone: {
              offset: parseInt(this.userProfile.profile.profile.timezoneOffset, 10),
            },
          } : null,
          payload: this.formatTicketBets,
        }).then((response) => {
          this.checkTicket(response.data.requestUuid);
          const message = {
            message: this.translations.general_ticket_resolving,
            status: 'neutral',
          };
          this.setNotification(message);
        }).catch((error) => {
          if (error) {
            const message = {
              message: this.translations.general_ticket_error,
              status: 'negative',
            };
            store.dispatch('betslip/setTicketPayinLoader', false);
            this.setNotification(message);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            this.setScheduleDisabled(false);
            this.stopBetting(0);
            this.setIsPayinButtonDisabled(false);
          }
          if (error.response) {
            const errorData = error.response.data;
            const message = {
              message: errorData.message,
              status: 'negative',
            };
            store.dispatch('betslip/setTicketPayinLoader', false);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            this.setNotification(message);
          }
          return error;
        });
      }
    });
    eventBus.$on('TicketPayin', () => {
      if (this.isTerminal) {
        const additionalInfo = {
          printTemplate: this.getPrintTemplate(),
          timezone: {
            offset: 1,
          },
        };
        store.dispatch('gamesBetslip/payIn',
          {
            payload: this.formatTicketBets,
            additionalInfo: this.isIsonis ? additionalInfo : null,
          }).then((response) => {
          const config = {
            ticketGroup: this.config.productName,
          };
          const formatedPayload = response;
          formatedPayload.ticket.stake = response.ticket.payin;
          formatedPayload.ticket.config = config;
          if (formatedPayload.ticket.payin > this['user/user'].balance) {
            this.busService.sendMessage({
              action: 'Dialog.Show',
              data: {
                message: this.translations['notifications.insufficient_funds'],
                type: 'warning',
                delay: 3000,
              },
            }, true);
            return;
          }
          this.setScheduleDisabled(true);
          store.dispatch('gamesBetslip/setIsPayinInProgress', true);
          this.setIsPayinInProgress(true);
          this.stopBetting(this.currentRound);
          this.setIsPayinButtonDisabled(true);
          store.dispatch('setPayinProgress', true);
          this.busService.clientAdapter.handler.emitAsync({
            action: 'Tickets.Pay',
            data: formatedPayload,
            async: true,
          }).then((response2) => {
            this.checkTicket(response2.ticket.requestUuid);
          });
        });
      }
    });
    eventBus.$on('BetRemoved', () => {
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      window.dispatchEvent(clickEvent);
    });
    eventBus.$on('ReturnToLobby', () => {
      this.busService.sendMessage({
        action: 'UI.Show',
        data: {
          name: 'Lobby',
        },
      }, true);
      if (this.isAndroidPlatform) {
        window.WebAppListener.sendMessage('Navigation.Back');
      }
    });
    eventBus.$on('PrintTicketCopy', (ticket) => {
      const mappedTicket = assign(ticket, { isCopy: true }, { translation: this.productNameLabel });
      this.busService.sendMessage({
        action: 'Peripherals.Print',
        enforceEvent: true,
        data: {
          type: 'ticket',
          productId: 'GreyhoundRaces',
          data: mappedTicket,
        },
      }, true);
    });
    eventBus.$on('PayoutTicket', (ticket) => {
      this.busService.sendMessage({
        action: 'Tickets.Payout',
        data: {
          ticket,
        },
      }, true);
    });
    eventBus.$on('TicketCancel', (ticket) => {
      this.busService.sendMessage({
        action: 'Tickets.Cancel',
        data: {
          ticket,
        },
      }, true);
    });
    if (this.config.platformConfig.clientType) {
      if (this.userProfile && this.userProfile?.profile?.id) {
        this.togglePlayerChannel(this.userProfile.profile.id);
        this.getLastTickets();
      }
    }
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  -webkit-tap-highlight-color: transparent;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  &.hide-cursor {
    * {
      cursor: none !important;
    }
  }
}
.app {
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
  height: 100vh;
  .cancel-ticket-confirm-modal {
    .modal {
      background-color: var(--card);

      .modal-header, .modal-body {
        color: var(--text-primary-1);
      }

      .modal-header {
        font-size: 16px;
        padding: 0 16px;
      }
      .modal-body {
        font-size: 14px;
        .modal-actions {
          display: none;
        }
      }
      .modal-footer {
        padding-right: 43px;
      }
      .button {
        background: transparent;
        border: 0;
        outline: 0;
        font-size: 14px;
        color: #ffc107;
        text-transform: uppercase;
        cursor: pointer;
        font-family: Roboto, 'sans-serif';
        padding: 31px 0 27px 0;
      }
    }
  }
}
::v-deep .ticket-details-body-desktop {
  .rows {
    overflow: hidden !important;
  }
}
.modal-back.cancel-ticket {
  .modal.show-modal {
    .modal-header {
      font-size: 18px;
      font-weight: 700;
      h3 {
        margin: 0 !important;
        font-size: 18px !important;
        text-align: left;
      }
    }
    .modal-body {
      font-size: 16px !important;
      text-align: left;
    }
    .modal-footer {
      .button {
        margin-left: 60px;
      }
    }
  }
  .modal.popup-show-modal {
    padding: 24px !important;
    .modal-header {
      padding-left: 0px !important;
      h3 {
        margin: 0 !important;
        font-size: 18px !important;
      }
    }
    .modal-body {
      font-size: 16px !important;
    }
  }
}
@media all and (max-width: 420px) {
  .modal-back.cancel-ticket {
    padding: 0px;
    .modal.show-modal {
      .modal-header {
        h3 {
          margin: 0 !important;
          font-size: 18px !important;
        }
      }
      .modal-body {
        font-size: 16px !important;
      }
    }
    .modal.popup-show-modal {
      .modal-header {
        padding-left: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        font-size: 18px;
        font-weight: 700;
        h3 {
          margin: 0 !important;
          font-size: 18px !important;
        }
      }
      .modal-body {
        font-size: 16px !important;
        padding: 15px 0px 0px 0px !important;
      }
      .modal-footer {
        padding: 0px 0px 0px 30px !important;
        .button {
          margin-left: 60px;
        }
      }
    }
  }
}
@media (max-width: 640px) {
  .app {
    .cancel-ticket-confirm-modal {
      background-color: var(--overlay-lock);
      .modal {
        background-color: var(--card);
        color: var(--text-primary-1);
        height: 200px !important;

        .modal-header {
          background-color: var(--card);
          padding: 0 24px 16px 24px;
        }

        .modal-body {
          position: relative;
          padding: 0 24px;
          color: var(--text-primary-1);
          .modal-actions {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            padding-right: 43px;
          }
        }

        .modal-footer {
          display: none;
        }
      }
    }
  }
}
</style>
